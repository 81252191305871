import dayjs from 'dayjs';
import { IUser } from 'app/shared/model/user.model';

export interface ISubscriptionLimit {
  id?: number;
  active?: boolean | null;
  orderId?: string | null;
  planLimit?: string | null;
  balance?: string | null;
  limitStartDate?: dayjs.Dayjs | null;
  limitEndDate?: dayjs.Dayjs | null;
  cfBalance?: string | null;
  internalUser?: IUser | null;
}

export const defaultValue: Readonly<ISubscriptionLimit> = {
  active: false,
};
