import {
  getEntities,
  getEntity,
  updateEntity,
  getAiChatBYSearchQuery,
  getRemainingMessagesLimit,
  getUpgrade,
  createEntity,
} from '../../entities/ai-chat/ai-chat.reducer';
import { getAccount } from '../../../../../app/shared/reducers/authentication';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { overridePaginationStateWithQueryParams } from '../../../../../app/shared/util/entity-utils';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from '../../../../../app/shared/util/pagination.constants';
import AichatListView from './AichatListView';
import DefaultPrompts from './default-prompts';
import GeneratedmessageDetailsView from './GeneratedmessageDetailsView';
import UserpromptFormView from './UserpromptFormView';
import React, { useEffect, useState, useRef } from 'react';
import { Route } from 'react-router-dom';
import { Row, Col, Button, Navbar, Nav, NavbarToggler, Collapse, Tooltip } from 'reactstrap';
import { getSortState, ValidatedField, ValidatedForm } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from '../../../../../app/config/store';
import { Persona } from '../../../../../app/shared/model/enumerations/persona.model';
import { setSidebarToggle } from '../../entities/sidebar/sidebar.reducer';
import {
  setShowTemplates,
  setUserOldConversationId,
  setIsOpenChatList,
  setIsOpenFavourites,
  setUserOldConversation,
  setShowExtraPrompts,
  setShowSubscriptionAlert,
  setSelectedPrompt,
} from '../../entities/chat-dashboard/chat-dashboard.reducer';
import LoadingBar from 'react-redux-loading-bar';
import { AdminMenu, EntitiesMenu, AccountMenu, LocaleMenu } from '../../../../../themes/cpapilot/app/shared/layout/menus/';
import {
  MsgSvgIcon,
  StarSvgIcon,
  UserPromptSvgIcon,
  TutorialsSvgIcon,
  SettingSvgIcon,
  QuestionSvgIcon,
  ArrowBackwordSvgIcon,
  ArrowForwordSvgIcon,
  UpgradePlanSvgIcon,
} from '../../../../../app/shared/icon-svg/icon';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { renderToString } from 'react-dom/server';

interface Props {
  cardCols?: string;
}

export const ChatDashboard = (props: Props) => {
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, 1000, 'id', DESC), location.search)
  );
  const [error, setError] = useState('');
  const dispatch = useAppDispatch();
  const [userConversation, setUserConversation] = useState('');

  const [userConversationAI, setUserConversationAI] = useState<any>();
  const [userConversationId, setUserConversationId] = useState(null);
  const userOldConversationId = useAppSelector(state => state.chatDashboard.userOldConversationId);
  const userOldConversation = useAppSelector(state => state.chatDashboard.userOldConversation);
  //const [generatingChatGptConversation, setGeneratingChatGptConversation] = useState('');
  const [generatingChatGptConversationId, setGeneratingChatGptConversationId] = useState('');
  // const [selectedPersona, setSelectedPersona] = useState('AISalesAssistant');
  const [chatTitleLoaded, setChatTitleLoaded] = useState({
    chatId: null,
    isTitleLoaded: false,
  });
  //const [isOpenFavourites, setIsOpenFavourites] = useState(false);
  const isOpenFavourites = useAppSelector(state => state.chatDashboard.isOpenFavourites);
  //const [isOpenChatList, setIsOpenChatList] = useState(true);
  const isOpenChatList = useAppSelector(state => state.chatDashboard.isOpenChatList);
  const userPromptFullText = useAppSelector(state => state.chatDashboard.userPromptFullText);
  const personaValues = Object.entries(Persona);
  const toggleMenu = () => setMenuOpen(!menuOpen);
  const [menuOpen, setMenuOpen] = useState(false);
  const showTemplates = useAppSelector(state => state.chatDashboard.showTemplates);
  const newConversation = useAppSelector(state => state.aiChat.entity);
  const aiChatCreateErrorMessage = useAppSelector(state => state.aiChat.errorMessage);
  const [loading, setLoading] = useState(false);
  const [showLoading, setShowLoading] = useState(true);
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const userAccount = useAppSelector(state => state.authentication.account);
  const sidebarOpen = useAppSelector(state => state.sidebar.sidebarOpen);
  const [isDesktopSidrbarOpen, setIsDesktopSidrbarOpen] = useState(true);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const showExtraPrompts = useAppSelector(state => state.chatDashboard.showExtraPrompts);
  const mobileViewNewChatButtonClicked = useAppSelector(state => state.chatDashboard.mobileViewNewChatButtonClicked);
  const account = useAppSelector(state => state.authentication.account);
  const messageCount = useAppSelector(state => state.aiChat.messageCount);
  const userName = useAppSelector(state => state.authentication.account.firstName);
  const [userNameInital, setUserNameInital] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const searchQueryRef = useRef(null);
  const [showUpgradeNow, setShowUpgradeNow] = useState(false);
  const [showUpgradeEarly, setShowUpgradeEarly] = useState(false);
  const [isTrialValid, setIsTrialValid] = useState(false);
  const [isPromotMessage, setisPromotMessage] = useState('');
  const [subscribeNow, setSubscribeNow] = useState(false);
  const [showAddButton, setShowAddButton] = useState(false);

  const configUserChars = userAccount => {
    if (userAccount.firstName != null && userAccount.firstName != undefined && userAccount.firstName != '') {
      setUserNameInital(userAccount.firstName.charAt(0));
    } else {
      setUserNameInital(userAccount?.email.charAt(0));
    }
  };

  useEffect(() => {
    configUserChars(userAccount);
  }, []);

  useEffect(() => {
    const savedChatData = localStorage.getItem('chatData');
    if (savedChatData) {
      const parsedData = JSON.parse(savedChatData);
      const currentPrompt = parsedData.currentPrompt;
      const currentChatId = parsedData.currentChatId;

      if (currentPrompt && currentPrompt != '') {
        dispatch(setSelectedPrompt(currentPrompt));
      }

      if (currentChatId && currentChatId != '') {
        loadUserOldConversation(currentChatId);
      }
    }
  }, []);

  useEffect(() => {
    if (aiChatCreateErrorMessage == 'Request failed with status code 422') {
      setUserConversation('');
      // setLoading(false);
      dispatch(setShowSubscriptionAlert(true));
      useFetchAccountDetails();
      useFetchMessagesLimit();
    }
  }, [aiChatCreateErrorMessage]);
  useEffect(() => {
    // Run code only for new conversations
    let newChatId = newConversation.id;
    if (newConversation.title == 'New Chat') {
      setUserConversationId(newChatId);
      handleUpdateChatTitle(newChatId, false);
      dispatch(
        getEntities({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
        })
      );
    }
  }, [newConversation]);

  useEffect(() => {
    if (account.subscriptionActive) {
      dispatch(setShowSubscriptionAlert(false));
      dispatch(setShowTemplates(true));
      const savedChatData = localStorage.getItem('chatData');
      if (savedChatData) {
        const parsedData = JSON.parse(savedChatData);
        const currentPrompt = parsedData.currentPrompt;

        if (currentPrompt && currentPrompt != '') {
          dispatch(setSelectedPrompt(currentPrompt));
        }
      }
    }
  }, [account.subscriptionActive]);

  useEffect(() => {
    const { planType, trial, subscriptionActive } = account || {};
    const isBalanceZero = parseInt(messageCount.balance) === 0;
    let promotMessage = 'Message CPAPilot';
    let showUpgradeEarly = false;
    let showUpgradeNow = false;
    let subscribeNow = false;
    let showAddButton = false;

    if (planType === 'trial') {
      if (trial && isBalanceZero) {
        showUpgradeNow = true;
        promotMessage = 'Limit Exhausted. You may renew/upgrade your subscription.';
      } else {
        subscribeNow = true;
        promotMessage = 'No Subscription found. Please Subscribe to access service.';
      }
    } else if (planType === 'paid') {
      if (trial && isBalanceZero) {
        showUpgradeEarly = true;
        promotMessage = 'Trial limit Exhaused. You may Pay Now to end trial and continue accessing the service.';
      } else if (subscriptionActive && isBalanceZero) {
        showUpgradeNow = false;
        showAddButton = true;
        promotMessage = 'Limit Exhausted. You may renew/upgrade your subscription.';
      } else if (!subscriptionActive) {
        subscribeNow = true;
        promotMessage = 'Please renew your Subscription.';
      }
    }

    setShowUpgradeEarly(showUpgradeEarly);
    setShowUpgradeNow(showUpgradeNow);
    setSubscribeNow(subscribeNow);
    setisPromotMessage(promotMessage);
    setShowAddButton(showAddButton);
  }, [account, messageCount]);

  // useEffect(() => {
  //   if (account.trial && account.subscriptionActive) {
  //     if (messageCount.balance != 0 && messageCount.balance != null) {
  //       if (account.planType == 'trail') {
  //         setShowUpgradeEarly(false);
  //         setShowUpgradeNow(true);
  //       } else {
  //         setShowUpgradeEarly(false);
  //         setShowUpgradeNow(false);
  //       }
  //       dispatch(setShowSubscriptionAlert(false));
  //     } else {
  //       if (account.planType == 'trail') {
  //         setShowUpgradeEarly(false);
  //         setShowUpgradeNow(true);
  //       } else {
  //         setShowUpgradeEarly(true);
  //         setShowUpgradeNow(false);
  //       }
  //       dispatch(setShowSubscriptionAlert(true));
  //     }
  //   } else if (!account.trial && !account.subscriptionActive) {
  //     if (account.planType == 'trail') {
  //       setShowUpgradeEarly(false);
  //       setShowUpgradeNow(true);
  //     } else {
  //       setShowUpgradeEarly(true);
  //       setShowUpgradeNow(false);
  //     }
  //     dispatch(setShowSubscriptionAlert(true));
  //   }
  // }, [account, messageCount, dispatch]);

  // useEffect(() => {
  //   const fetchMessagesLimit = async () => {
  //     let resp = await dispatch(getRemainingMessagesLimit());
  //     let balance = resp && resp.payload.data.balance;
  //     if (balance == 0) {
  //       dispatch(setShowSubscriptionAlert(true));
  //     }
  //   };
  //   fetchMessagesLimit(); // Initial fetch
  // }, []);

  useEffect(() => {
    setUserConversation('');
  }, [mobileViewNewChatButtonClicked]);

  useEffect(() => {
    useFetchAccountDetails();
    useFetchMessagesLimit();
  }, []);

  /** Call Fetch Account details until account is active status */
  const useFetchAccountDetails = () => {
    let interval;
    const fetchAccountDetails = async () => {
      await dispatch(getAccount());
      if (!account.subscriptionActive) {
        if (!interval) {
          interval = setInterval(fetchAccountDetails, 15000); // Fetch every 15 seconds
        }
      } else if (interval) {
        clearInterval(interval); // Clear the interval if balance is not zero
        interval = null;
      }
    };
    fetchAccountDetails();
  };
  /** Call Fetch message limit until limit is not null or zero */
  const useFetchMessagesLimit = () => {
    let interval;
    const fetchMessagesLimit = async () => {
      let resp = await dispatch(getRemainingMessagesLimit());
      let balance = resp && resp.payload.data.balance;
      if (balance == 0 || balance == null) {
        if (!interval) {
          interval = setInterval(fetchMessagesLimit, 15000); // Fetch every 15 seconds
        }
      } else if (interval) {
        clearInterval(interval); // Clear the interval if balance is not zero
        interval = null;
      }
    };
    fetchMessagesLimit(); // Initial fetch
  };

  const loadUserOldConversation = async chatId => {
    let response = await dispatch(getEntity(chatId));
    let chatStr = '';
    if (response.payload.data) {
      let generatedMessages = response.payload.data.generatedMessages;
      if (generatedMessages && generatedMessages.length > 0) {
        dispatch(setShowTemplates(false));
        generatedMessages.map(message => {
          let aiResp = message.rawMessage.includes('<!DOCTYPE html>')
            ? message.rawMessage
            : renderToString(
                <Markdown
                  remarkPlugins={[remarkGfm]}
                  components={{
                    a: ({ node, ...props }) => {
                      return (
                        <a {...props} target="_blank">
                          {props.children}
                        </a>
                      );
                    },
                  }}
                >
                  {message.rawMessage}
                </Markdown>
              );
          chatStr +=
            '<div class="usertext"><strong><span>' +
            userName[0] +
            '</span> You</strong>' +
            (message.userPrompt && message.userPrompt.promptText ? message.userPrompt.promptText : message.aiChat.title) +
            '</div>' +
            '<div class="cpaText"><strong><img class="cpapilot-chat-logo" src="content/images/logo.png" alt="Logo">CPA Pilot</strong></div>' +
            aiResp;
        });
        handleUserSelectedConversation(chatId, chatStr);
      }
    }
  };

  const reloadAllEntitiesOnStreamingEnd = () => {
    if (userConversationId == chatTitleLoaded.chatId && !chatTitleLoaded.isTitleLoaded) {
      dispatch(
        getEntities({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
        })
      );
    }
    setChatTitleLoaded({
      ...chatTitleLoaded,
      isTitleLoaded: true,
    });
  };

  const updateConversation = async generatedMessage => {
    let aiResp = renderToString(
      <Markdown
        remarkPlugins={[remarkGfm]}
        components={{
          a: ({ node, ...props }) => {
            return (
              <a {...props} target="_blank">
                {props.children}
              </a>
            );
          },
        }}
      >
        {generatedMessage}
      </Markdown>
    );
    setUserConversation(userConversation => userConversation + aiResp);
  };

  const handleConversation = async generatedMessage => {
    //setUserConversation([...userConversation, generatedMessage]);
    // setUserConversation(userConversation => userConversation + generatedMessage);
    // setGeneratingChatGptConversation(generatingChatGptConversation => generatingChatGptConversation + generatedMessage);
    setUserConversationAI(generatedMessage);
    //setGeneratingChatGptConversation(generatedMessage);
  };

  const handleUserSelectedConversation = async (id, oldConversation) => {
    dispatch(setUserOldConversationId(id));
    // dispatch(setUserOldConversation(oldConversation));

    if (id == generatingChatGptConversationId) {
      dispatch(setShowTemplates(false));
      setUserConversation(oldConversation + userPromptFullText + (userConversationAI ? userConversationAI : ''));
      setShowLoading(true);
    } else {
      if (oldConversation == '') {
        dispatch(setShowTemplates(true));
      }
      setUserConversation(oldConversation);
      setShowLoading(false);
    }
  };

  const onStartNewConversation = async () => {
    await dispatch(createEntity({}));
  };

  const onClickStartNewChatPlusButton = async () => {
    // dispatch(setUserOldConversation(''));
    setUserConversation('');
    dispatch(setUserOldConversationId(null));
    dispatch(setShowTemplates(true));
    dispatch(setIsOpenFavourites(false));
    dispatch(setIsOpenChatList(true));
    dispatch(setShowExtraPrompts(false));
    await dispatch(createEntity({}));
  };

  const handleUpdateChatTitle = (chatId, isTitleLoaded) => {
    setChatTitleLoaded({
      ...chatTitleLoaded,
      isTitleLoaded: isTitleLoaded,
      chatId: chatId,
    });
  };

  // const handleSelectChange = event => {
  //   // Handle select persona change
  //   setSelectedPersona(event.target.value);
  // };

  // const setLastChatSelectedPersona = persona => {
  //   setSelectedPersona(persona);
  // };

  const handleShowChatList = () => {
    dispatch(setIsOpenFavourites(false));
    dispatch(setShowExtraPrompts(false));
    if (!isOpenChatList) {
      dispatch(
        getEntities({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
        })
      );
      dispatch(setIsOpenChatList(!isOpenChatList));
      if (searchQueryRef.current) {
        searchQueryRef.current.value = '';
      }
      setSearchQuery('');
    }
  };

  const handleOnChange = event => {
    const currentValue = event.target.value.trim();
    if (currentValue) {
      setError('');
    }
  };
  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      const currentValue = event.target.value;
      const query = currentValue.trim();
      event.preventDefault(); // Prevent default form submission behavior
      event.stopPropagation(); // Stop propagation to prevent multiple submissions
      if (query) {
        setSearchQuery(query);
        dispatch(getAiChatBYSearchQuery({ searchTitle: query, isFavorite: isOpenFavourites }));
      } else {
        setError('Please enter a search query.');
      }
    }
  };

  const handleClearSearchQuery = async () => {
    if (searchQuery) {
      setError('');
      await dispatch(
        getEntities({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
          query: `${isOpenFavourites ? `favourite.equals=${true}` : false}`,
        })
      );
    }
    if (searchQueryRef.current) {
      searchQueryRef.current.value = '';
    }
    setSearchQuery('');
  };

  const handleShowFavouriteList = () => {
    dispatch(setIsOpenChatList(false));
    dispatch(setShowExtraPrompts(false));
    setSearchQuery('');
    if (!isOpenFavourites) {
      dispatch(
        getEntities({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
          query: `favourite.equals=${true}`,
        })
      );
      dispatch(setIsOpenFavourites(!isOpenFavourites));
      if (searchQueryRef.current) {
        searchQueryRef.current.value = '';
      }
      setSearchQuery('');
    }
  };

  const closeSidebar = () => {
    dispatch(setSidebarToggle(!sidebarOpen));
  };

  const handleShowAllPrompts = () => {
    if (account.subscriptionActive && messageCount.balance != 0 && messageCount.balance != null) {
      dispatch(setShowExtraPrompts(!showExtraPrompts));
      dispatch(setIsOpenFavourites(false));
      dispatch(setIsOpenChatList(false));
    }
  };
  const IconWithTooltip = ({ id, tooltipText }) => {
    const [iconTooltipOpen, setIconTooltipOpen] = useState(false);
    const toggle = () => setIconTooltipOpen(!iconTooltipOpen);
    return (
      <Tooltip placement="right" isOpen={iconTooltipOpen} target={id} toggle={toggle}>
        {tooltipText}
      </Tooltip>
    );
  };

  const handleUpgradeEarly = async () => {
    const { payload } = await dispatch(getUpgrade());
    console.log('response :: ', payload);
    const { status, data } = payload;
    if (status == 200) {
      debugger;
      window.open(data, '_blank');
    }
  };

  return (
    <div className="min-hight1">
      <Row className="min-hight">
        <Col
          xs="4"
          className={sidebarOpen ? 'mobilesidebar_open bg-white bg-clrside sidebar p-0 flex' : 'bg-white bg-clrside sidebar p-0 flex'}
        >
          <div className=" flex flex-col h-full w-[80px] right-border">
            <div className="flex h-16 w-full items-center justify-center">
              <img src="content/images/logo.png" alt="Logo" />
            </div>
            <div>
              <div className="flex h-16 w-full items-center justify-center">
                <a onClick={handleShowChatList} id="chatIcon" className={isOpenChatList ? 'active1' : {}}>
                  <MsgSvgIcon />
                </a>
                <IconWithTooltip id="chatIcon" tooltipText="Chat" />
              </div>
              <div className="flex h-16 w-full items-center justify-center">
                <a className="icon-name" id="favouriteIcon" onClick={handleShowFavouriteList} className={isOpenFavourites ? 'active1' : {}}>
                  <StarSvgIcon />
                </a>
                <IconWithTooltip id="favouriteIcon" tooltipText="Favorites" />
              </div>
              <div className="flex h-16 w-full items-center justify-center">
                <a onClick={handleShowAllPrompts} id="promptIcon" className={showExtraPrompts ? 'active1' : {}}>
                  <UserPromptSvgIcon />
                </a>
                <IconWithTooltip id="promptIcon" tooltipText="Prompts" />
              </div>
            </div>
            <div className="mt-auto">
              <div>
                <div className="flex h-16 w-full items-center justify-center">
                  <a id="tutorialIcon" href="https://medium.com/@support_56315/tutorial-797b5d76f284" target="_blank">
                    <TutorialsSvgIcon />
                  </a>
                  <IconWithTooltip id="tutorialIcon" tooltipText="Tutorial" />
                </div>

                <div className="flex h-16 w-full items-center justify-center">
                  <div id="sidebar-user-profile">
                    <LoadingBar className="loading-bar" />
                    <Navbar data-cy="navbar" dark expand="md" className="jh-navbar">
                      <NavbarToggler aria-label="Menu" onClick={toggleMenu} />
                      <Collapse isOpen={menuOpen} navbar>
                        <Nav id="header-tabs" className="ms-auto" navbar>
                          {/* {props.isAuthenticated && props.isAdmin && <AdminMenu showOpenAPI={props.isOpenAPIEnabled} />} */}
                          <AccountMenu isAuthenticated={isAuthenticated} userName={userNameInital} />
                        </Nav>
                      </Collapse>
                    </Navbar>
                  </div>
                </div>
              </div>
              {/* <div className="flex h-16 w-full items-center justify-center">
                {/* <a href="#" className="icon-name">
                  <SettingSvgIcon />
                </a>
              </div>
              {/* <div className="flex h-16 w-full items-center justify-center">
                <a href="#" className="icon-name">
                  <QuestionSvgIcon />
                </a>
              </div>  */}
            </div>
          </div>
          <Row
            className={
              sidebarOpen
                ? 'w-[220px] chat-history is-open'
                : isDesktopSidrbarOpen
                ? 'open-desktop-sidebar w-[220px] chat-history'
                : 'w-[220px] chat-history'
            }
          >
            <Col md="12">
              <Row className="mobile-display mb-2">
                <Col md="10">
                  <h5 className="chat-title">New Chat</h5>
                </Col>
                <Col md="2">
                  <div className="d-flex justify-content-end">
                    <Button color="warning" className="" onClick={onClickStartNewChatPlusButton} disabled={messageCount.balance == 0}>
                      <FontAwesomeIcon icon={'plus'} />
                    </Button>
                  </div>
                </Col>
              </Row>
              <div className="search-container">
                <ValidatedField
                  name="query"
                  type="text"
                  className="search-design mb-0"
                  placeholder="Search"
                  validate={{ required: { value: true, errorMessage: 'Please enter a search query.' } }}
                  onKeyPress={handleKeyPress} // Handle Enter key press
                  onChange={handleOnChange}
                  innerRef={searchQueryRef}
                />
                <button className="search-clear-button" onClick={handleClearSearchQuery}>
                  &times;
                </button>
              </div>
              {error && <div className="text-danger">{error}</div>}
            </Col>

            <Col md="12 fix-height">
              <AichatListView
                updateSelectedConversation={handleUserSelectedConversation}
                updateUserConversation={setUserConversation}
                // handleLastSelectedPersona={setLastChatSelectedPersona}
                userOldConversationId={userOldConversationId}
                isOpenFavourites={isOpenFavourites}
              />
            </Col>
            {sidebarOpen ? <button onClick={closeSidebar} type="button" className="sidebarcolse btn-close" aria-label="Close" /> : ''}
          </Row>
        </Col>
        <Col
          xs="8"
          className={
            sidebarOpen
              ? 'sidebackground bg-white p-3 mobile-width'
              : isDesktopSidrbarOpen
              ? 'is-open bg-white p-3 mobile-width'
              : 'bg-white p-3 mobile-width'
          }
        >
          {showExtraPrompts ? (
            <DefaultPrompts />
          ) : (
            <>
              <div className="desktop-open">
                <button id="openSidebarTooltip" onClick={() => setIsDesktopSidrbarOpen(!isDesktopSidrbarOpen)}>
                  {isDesktopSidrbarOpen ? <ArrowBackwordSvgIcon /> : <ArrowForwordSvgIcon />}
                </button>
                <Tooltip placement="right" isOpen={tooltipOpen} target="openSidebarTooltip" toggle={toggle}>
                  {isDesktopSidrbarOpen ? 'Close Sidebar' : 'Open Sidebar'}
                </Tooltip>
              </div>
              <div className="bg-clr content-inner">
                <div className="width-content">
                  <Row>
                    {/*<Col md="12" sm="12" lg="6" xl="3" className="legend-spac">*/}
                    {/*  <fieldset>*/}
                    {/*    <legend>Persona:</legend>*/}
                    {/*    <ValidatedField*/}
                    {/*      id="selectedOption"*/}
                    {/*      name="selectedOption"*/}
                    {/*      type="select"*/}
                    {/*      required*/}
                    {/*      className="select-persone"*/}
                    {/*      onChange={handleSelectChange}*/}
                    {/*      value={selectedPersona}*/}
                    {/*    >*/}
                    {/*      /!* <option value="">Select Persona</option> *!/*/}
                    {/*      {Object.keys(Persona).map(key => (*/}
                    {/*        <option value={key}>{Persona[key as keyof typeof Persona]}</option>*/}
                    {/*      ))}*/}
                    {/*    </ValidatedField>*/}
                    {/*  </fieldset>*/}
                    {/*</Col>*/}
                    {/* {showAddButton && (
                      <Col
                        md="12"
                        sm="12"
                        lg="6"
                        xl="12"
                        className="legend-spac mobile_leftcontent d-flex justify-content-end align-items-center"
                      >
                        <div className="Subscribeplan">
                          <a href="https://www.cpapilot.com/pricing-plans/" target="_blank">
                            <UpgradePlanSvgIcon /> Add Button
                          </a>
                        </div>
                      </Col>
                    )} */}
                    {subscribeNow && (
                      <Col
                        md="12"
                        sm="12"
                        lg="6"
                        xl="12"
                        className="legend-spac mobile_leftcontent d-flex justify-content-end align-items-center"
                      >
                        <div className="Subscribeplan">
                          <a href="https://www.cpapilot.com/pricing-plans/" target="_blank">
                            <UpgradePlanSvgIcon /> Subscribe Now
                          </a>
                        </div>
                      </Col>
                    )}
                    {showUpgradeNow && (
                      <Col
                        md="12"
                        sm="12"
                        lg="6"
                        xl="12"
                        className="legend-spac mobile_leftcontent d-flex justify-content-end align-items-center"
                      >
                        <div className="Subscribeplan">
                          <a href="https://www.cpapilot.com/pricing-plans/" target="_blank">
                            <UpgradePlanSvgIcon /> Upgrade Now
                          </a>
                        </div>
                      </Col>
                    )}
                    {showUpgradeEarly && (
                      <Col
                        md="12"
                        sm="12"
                        lg="6"
                        xl="12"
                        className="legend-spac mobile_leftcontent d-flex justify-content-end align-items-center"
                      >
                        <div className="Subscribeplan">
                          <a
                            href=""
                            target="_blank"
                            onClick={e => {
                              e.preventDefault();
                              handleUpgradeEarly();
                            }}
                          >
                            <UpgradePlanSvgIcon /> Upgrade Early
                          </a>
                        </div>
                      </Col>
                    )}
                  </Row>
                  <GeneratedmessageDetailsView
                    showTemplates={showTemplates}
                    userConversation={userConversation}
                    userConversationAI={userConversationAI}
                    loading={showLoading ? loading : false}
                  />
                  <UserpromptFormView
                    newConvMethod={onStartNewConversation}
                    setUserConversation={setUserConversation}
                    handleConversation={handleConversation}
                    updateConversation={updateConversation}
                    userConversationId={userConversationId}
                    userConversation={userConversation}
                    userOldConversationId={userOldConversationId}
                    setGeneratingChatGptConversationId={setGeneratingChatGptConversationId}
                    // selectedPersona={selectedPersona}
                    showTemplates={showTemplates}
                    setLoading={setLoading}
                    setShowLoading={setShowLoading}
                    loading={loading}
                    reloadEntitiesOnStreamingEnd={reloadAllEntitiesOnStreamingEnd}
                    showPromptMessage={isPromotMessage}
                  />
                </div>
              </div>
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ChatDashboard;
