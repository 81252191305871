import {
  getEntity,
  sendUserPrompt,
  createEntity,
  reset,
  getRemainingMessagesLimit,
  updateMessageCount,
} from '../../entities/ai-chat/ai-chat.reducer';
import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, ListGroupItem, Card, CardBody, CardTitle, CardText, Form, FormGroup, Input, Alert } from 'reactstrap';

import { ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';

import { useAppDispatch, useAppSelector } from '../../../../../app/config/store';
import templates from '../../../../../../webapp/templates_config.json';
import { MsgsSvgIcon, ArrowSvgIcon } from '../../../../../app/shared/icon-svg/icon';

import {
  setShowTemplates,
  setUserPromptFullText,
  setUserOldConversation,
  setShowExtraPrompts,
  setIsOpenChatList,
  setShowSubscriptionAlert,
  setSelectedPrompt,
} from '../../entities/chat-dashboard/chat-dashboard.reducer';
import PromptCarousel from './PromptCarousel';
import { fetchEventSource } from '@microsoft/fetch-event-source';

export const UserpromptFormView = props => {
  const userPromptRef = useRef(null);
  const dispatch = useAppDispatch();
  const [formKey, setFormKey] = useState(0); // State to force re-render of the form
  const [error, setError] = useState('');
  const [disablePromptSubmit, setDisablePromptSubmit] = useState(true);
  const [userPromptText, setUserPromptText] = useState('');
  const userAccount = useAppSelector(state => state.authentication.account);
  const userOldConversation = useAppSelector(state => state.chatDashboard.userOldConversation);
  const userPromptFullText = useAppSelector(state => state.chatDashboard.userPromptFullText);
  const showExtraPrompts = useAppSelector(state => state.chatDashboard.showExtraPrompts);
  const selectedPrompt = useAppSelector(state => state.chatDashboard.selectedPrompt);
  const showSubscriptionAlert = useAppSelector(state => state.chatDashboard.showSubscriptionAlert);
  const account = useAppSelector(state => state.authentication.account);
  const messageCount = useAppSelector(state => state.aiChat.messageCount);

  const [userNameInital, setUserNameInital] = useState('');
  const [userName, setUserName] = useState('');

  const configUserChars = userAccount => {
    if (userAccount.firstName != null && userAccount.firstName != undefined && userAccount.firstName != '') {
      setUserName(userAccount.firstName);
      setUserNameInital(userAccount.firstName.charAt(0));
    } else {
      setUserName('User');
      setUserNameInital(userAccount?.email.charAt(0));
    }
  };

  useEffect(() => {
    configUserChars(userAccount);
  }, []);

  useEffect(() => {
    if (props.userConversationId && userPromptText != '') {
      props.setUserConversation(props.userConversation + userPromptFullText);
      props.setLoading(true);
      props.setShowLoading(true);
      // let personaVal = props.selectedPersona && props.selectedPersona == 'AISalesAssistant' ? '' : props.selectedPersona;
      let data = {
        prompt: userPromptText,
        aiChatId: props.userConversationId,
        // persona: personaVal,
      };
      // Clear userPromptText before making the API call
      setUserPromptText('');
      getChatGptResponse(data);
    }
  }, [props.userConversationId]);

  useEffect(() => {
    if (userPromptRef.current && selectedPrompt) {
      userPromptRef.current.value = selectedPrompt;
      const scrollHeight = userPromptRef.current.scrollHeight;
      userPromptRef.current.style.height = scrollHeight + 'px';
      setDisablePromptSubmit(false);
    }
  }, [selectedPrompt]);

  const onSubmit = async e => {
    e.preventDefault();

    const prompt = userPromptRef.current.value.trim();

    // Save prompt and chatId to Local storage
    let aiChatId = props.userOldConversationId ? props.userOldConversationId : props.userConversationId;
    let chatData = { currentChatId: aiChatId, currentPrompt: prompt };
    localStorage.setItem('chatData', JSON.stringify(chatData));

    if (messageCount.planLimit != 'unlimited' && messageCount.planLimit != null) {
      const newMessageCount = { balance: messageCount.balance - 1, planLimit: messageCount.planLimit };
      dispatch(updateMessageCount(newMessageCount));
    }

    if (!prompt) {
      setError('Please enter message.');
      return;
    }
    setError('');

    if (userPromptRef.current) {
      userPromptRef.current.value = null;
      userPromptRef.current.style.height = '48px';
    }
    let userCompleteText =
      '<div class="usertext"><strong><span>' +
      userNameInital +
      '</span>You</strong>' +
      prompt +
      '</div>' +
      '<div class="cpaText"><strong><img class="cpapilot-chat-logo" src="content/images/logo.png" alt="Logo"> CPA Pilot</strong></div>';
    setUserPromptText(prompt);
    dispatch(setUserPromptFullText(userCompleteText));
    dispatch(setShowTemplates(false));
    setDisablePromptSubmit(true);

    if (props.userOldConversationId || props.userConversationId) {
      props.setUserConversation(props.userConversation + userCompleteText);
      props.setLoading(true);
      props.setShowLoading(true);
      // let personaVal = props.selectedPersona && props.selectedPersona == 'AISalesAssistant' ? '' : props.selectedPersona;
      let data = {
        prompt: prompt,
        aiChatId: props.userOldConversationId ? props.userOldConversationId : props.userConversationId,
        // persona: personaVal,
      };
      getChatGptResponse(data);
    } else {
      props.newConvMethod();
    }
  };

  const getChatGptResponse = async data => {
    const prompt = encodeURIComponent(data.prompt);
    const url = `api/user-chat?chatId=${data.aiChatId}&prompt=${prompt}`;
    // const url = `api/user-chat?chatId=${data.aiChatId}&persona=${data.persona}&prompt=${prompt}`;

    props.setGeneratingChatGptConversationId(data.aiChatId);

    let chatGptFinalResp = '';

    await fetchEventSource(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-XSRF-TOKEN': getCookie('XSRF-TOKEN')?.toString(),
        Accept: 'text/event-stream',
      },
      openWhenHidden: true,
      onopen(res) {
        if (res.status == 422) {
          props.setUserConversation('');
          dispatch(setShowSubscriptionAlert(true));
        }
        chatGptFinalResp = '';
      },
      onmessage(event) {
        chatGptFinalResp = event.data;
        props.handleConversation(event?.data);
      },
      onclose() {
        setUserPromptText('');
        dispatch(getRemainingMessagesLimit());
        props.setLoading(false);
        props.setGeneratingChatGptConversationId('');
        props.reloadEntitiesOnStreamingEnd();
        props.updateConversation(chatGptFinalResp);
        props.handleConversation(false);
        localStorage.setItem('chatData', JSON.stringify({ currentChatId: '', currentPrompt: '' }));
        dispatch(setSelectedPrompt(''));
      },
      onerror(err) {
        console.log('ERROR :: ', err);
      },
    });
  };

  const handleInputChange = event => {
    if (userPromptRef.current && event.target.value.trim()) {
      userPromptRef.current.value = event.target.value;
      setDisablePromptSubmit(false);
      const scrollHeight = userPromptRef.current.scrollHeight;
      userPromptRef.current.style.height = scrollHeight + 'px';
    } else {
      setDisablePromptSubmit(true);
      userPromptRef.current.style.height = '48px';
    }
  };
  const setFieldValue = value => {
    //setUserPromptFieldValue(value);
    // if (messageCount.balance != 0 && messageCount.balance != null) {
    if (userPromptRef.current && value) {
      userPromptRef.current.value = value;
      const scrollHeight = userPromptRef.current.scrollHeight;
      userPromptRef.current.style.height = scrollHeight + 'px';
      setDisablePromptSubmit(false);
    } else {
      setDisablePromptSubmit(true);
      userPromptRef.current.style.height = '48px';
    }
    // }
  };

  const getCookie = name => {
    const value = `; ${document.cookie}`;
    const parts = value?.split(`; ${name}=`);
    if (parts.length === 2) return parts?.pop().split(';')?.shift();
  };

  const handleShowAllPrompts = () => {
    // if (messageCount.balance != 0 && messageCount.balance != null) {
    dispatch(setShowExtraPrompts(!showExtraPrompts));
    dispatch(setIsOpenChatList(false));
    //  }
  };

  return (
    <>
      <div className=" mt_md mt-3">
        {!showSubscriptionAlert && props.showTemplates && (
          <>
            <h1 className="heading-design">
              <span>Hello {userName},</span>
              How can I help you today?
            </h1>
            <h6 className="propmt-heding">You can choose a sample prompt from our library to get started.</h6>
            <Row className="exmaple-box">
              {templates &&
                templates.data.map((prompt, index) => (
                  <div className="col-md-6 col-6 col-lg-6 col-xl-3 option-feature" key={index}>
                    <Card onClick={() => (props.loading ? '' : setFieldValue(prompt))}>
                      <CardBody>
                        <CardTitle>{prompt}</CardTitle>
                      </CardBody>
                    </Card>
                  </div>
                ))}
              <PromptCarousel prompts={templates.data} setPromptFieldValue={prompt => setFieldValue(prompt)} />
              <a className="view_prompt" onClick={handleShowAllPrompts}>
                View Sample Prompt Library <ArrowSvgIcon />
              </a>
            </Row>
          </>
        )}
        {showSubscriptionAlert && (
          <Row className="subscription-reniw">
            <Col xs="12" className="msg-box-content">
              <div className="plan-text-sec">
                {messageCount.balance == 0 ? (
                  <>
                    It seems that you've reached your limit of {messageCount.planLimit} messages/month. For plan upgrade{' '}
                    <a href="https://www.cpapilot.com/pricing-plans/" target="_blank" rel="noopener noreferrer">
                      Click Here
                    </a>
                  </>
                ) : (
                  <>
                    <div className="signup-plan-text">
                      Please sign up for a plan{' '}
                      <a href="https://www.cpapilot.com/pricing-plans/" target="_blank" rel="noopener noreferrer">
                        here
                      </a>
                      .
                    </div>{' '}
                    We offer a free plan so you can get started with CPA Pilot.{' '}
                    <div>
                      If you are already subscribed to a plan please email to <a href="mailto:support@cpapilot.com">support@cpapilot.com</a>{' '}
                      because there seems to be an issue with your account.
                    </div>
                  </>
                )}
              </div>
            </Col>
          </Row>
        )}
      </div>
      <Row className="exmaple-box">
        <Col xs="12" className="msg-box-content">
          <span>
            {messageCount.balance == 'unlimited'
              ? 'unlimited messages'
              : messageCount.balance != null
              ? `${messageCount.balance} message(s) left`
              : ''}
          </span>
          <Form onSubmit={onSubmit} key={formKey} className="msg-box float-left msg-design">
            <FormGroup>
              <Input
                id="userPrompt"
                placeholder={props?.showPromptMessage ? props.showPromptMessage : ''}
                name="userPrompt"
                data-cy="PromptText"
                type="textarea"
                className="float-left msg-design"
                innerRef={userPromptRef}
                onChange={handleInputChange}
                rows={1}
                disabled={messageCount.balance == 0}
              />
            </FormGroup>
            &nbsp;
            <Button
              color="success"
              id="save-entity"
              type="submit"
              outline
              disabled={disablePromptSubmit || props.loading}
              className="ms-2 float-right submit-prompt-btn"
            >
              <MsgsSvgIcon />
            </Button>
          </Form>
          {error && <div className="text-danger">{error}</div>}
          <p className="para-design">
            {' '}
            CPA Pilot may display inaccurate info. Please use the sources in its response to verify the answers it provides. Do not enter
            any Personal or sensitive information.
          </p>
        </Col>
      </Row>
    </>
  );
};

export default UserpromptFormView;
