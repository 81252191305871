import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './subscription-limit.reducer';

export const SubscriptionLimitDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const subscriptionLimitEntity = useAppSelector(state => state.subscriptionLimit.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="subscriptionLimitDetailsHeading">
          <Translate contentKey="cpapilotApp.subscriptionLimit.detail.title">SubscriptionLimit</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{subscriptionLimitEntity.id}</dd>
          <dt>
            <span id="active">
              <Translate contentKey="cpapilotApp.subscriptionLimit.active">Active</Translate>
            </span>
          </dt>
          <dd>{subscriptionLimitEntity.active ? 'true' : 'false'}</dd>
          <dt>
            <span id="orderId">
              <Translate contentKey="cpapilotApp.subscriptionLimit.orderId">Order Id</Translate>
            </span>
          </dt>
          <dd>{subscriptionLimitEntity.orderId}</dd>
          <dt>
            <span id="planLimit">
              <Translate contentKey="cpapilotApp.subscriptionLimit.planLimit">Plan Limit</Translate>
            </span>
          </dt>
          <dd>{subscriptionLimitEntity.planLimit}</dd>
          <dt>
            <span id="balance">
              <Translate contentKey="cpapilotApp.subscriptionLimit.balance">Balance</Translate>
            </span>
          </dt>
          <dd>{subscriptionLimitEntity.balance}</dd>
          <dt>
            <span id="limitStartDate">
              <Translate contentKey="cpapilotApp.subscriptionLimit.limitStartDate">Limit Start Date</Translate>
            </span>
          </dt>
          <dd>
            {subscriptionLimitEntity.limitStartDate ? (
              <TextFormat value={subscriptionLimitEntity.limitStartDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="limitEndDate">
              <Translate contentKey="cpapilotApp.subscriptionLimit.limitEndDate">Limit End Date</Translate>
            </span>
          </dt>
          <dd>
            {subscriptionLimitEntity.limitEndDate ? (
              <TextFormat value={subscriptionLimitEntity.limitEndDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="cfBalance">
              <Translate contentKey="cpapilotApp.subscriptionLimit.cfBalance">Cf Balance</Translate>
            </span>
          </dt>
          <dd>{subscriptionLimitEntity.cfBalance}</dd>
          <dt>
            <Translate contentKey="cpapilotApp.subscriptionLimit.internalUser">Internal User</Translate>
          </dt>
          <dd>{subscriptionLimitEntity.internalUser ? subscriptionLimitEntity.internalUser.login : ''}</dd>
        </dl>
        <Button tag={Link} to="/subscription-limit" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/subscription-limit/${subscriptionLimitEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default SubscriptionLimitDetail;
